"use client";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLink,
  EuiText,
  EuiTitle,
} from "@equipmentshare/ds2";
import {
  EXTERNAL_URLS,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";
import { useEffect } from "react";

import { CONSTANTS } from "@/config/constants";
import { staticImageAsset } from "@/config/staticAssets";

export default function ErrorComponent({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const { notifyParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES
      .T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE,
  });

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <EuiFlexGroup
      alignItems="center"
      className="h-screen"
      data-testid="error-component"
      direction="column"
      justifyContent="center"
    >
      <EuiFlexItem grow={false}>
        <EuiImage
          alt={staticImageAsset.error.alt}
          className="pb-8"
          src={staticImageAsset.error.src}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiTitle size="m">
          <h2>Something went wrong.</h2>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem className="sm:max-w-sm max-w-md" grow={false}>
        <EuiText color="subdued" size="m">
          <p>
            We&apos;re currently experiencing technical difficulties, but our
            team is working hard to resolve the issue. Please try again later or{" "}
            <EuiLink onClick={() => notifyParentWindow()}>
              contact customer support
            </EuiLink>{" "}
            for assistance.
          </p>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
